import { graphql, StaticQuery } from 'gatsby'
import Img from 'gatsby-image'
import React from 'react'

export function MailSuccessIcon() {
	return (
		<StaticQuery
			query={graphql`
				query {
					file: file(relativePath: { eq: "mail-success.png" }) {
						childImageSharp {
							fluid(maxWidth: 1600) {
								...GatsbyImageSharpFluid
							}
						}
					}
				}
			`}
			render={data => (
				<Img style={{ width: 60 }} fluid={data.file.childImageSharp.fluid} />
			)}
		/>
	)
}
