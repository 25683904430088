const MessageTypes = {
	ACADEMY: 'Cyber Academy',
	CTF: 'Hackathon & CTF',
	ASSESSMENT: 'Skill Assessment',
	DEMO_REQUEST: 'Demo Request',
}

export type MessageTypeKey = keyof typeof MessageTypes

export default MessageTypes
