import { graphql, PageProps, useStaticQuery } from 'gatsby'

import { Helmet } from 'react-helmet'
import React, { useState } from 'reactn'
import styled from 'styled-components/macro'
import { postMessage } from '../apiClients/messages'
import ErrorText from '../components/ErrorText'
import FlatButton from '../components/FlatButton'
import Heading from '../components/Heading'
import Layout from '../components/Layout'
import Link from '../components/Link'
import { MailSuccessIcon } from '../components/MailSuccessIcon'
import TextInput from '../components/TextInput'
import { useAppContext } from '../context'
import ArrayPayload from '../models/ArrayPayload'
import GlobalSetting, { SettingKey } from '../models/GlobalSetting'
import Message from '../models/Message'
import { PageContext } from '../models/PageContext'
import { buildMetaTitle } from '../utils/meta'

interface Props extends PageProps<object, PageContext, { type: string }> {
	messageType?: string
}

interface PageQueryResult {
	settings: {
		nodes: ArrayPayload<GlobalSetting>[]
	}
}

export default function ContactPage({
	location,
	messageType,
	pageContext: { displayName },
}: Props) {
	const {
		settings: { nodes: settings },
	} = useStaticQuery<PageQueryResult>(graphql`
		query {
			settings: allStrapiGlobalsettings {
				nodes {
					data {
						id
						attributes {
							key
							value
						}
					}
				}
			}
		}
	`)

	const [contactForm, setContactForm] = useState<Message>({
		email: '',
		name: '',
		text: '',
		company: '',
		phone: '',
	})

	const [error, setError] = useState<string>()
	const [loading, setLoading] = useState<boolean>(false)
	const [done, setDone] = useState<boolean>(false)
	const { getSettingValue } = useAppContext()

	const sendOrder = async () => {
		const ok = await postMessage({
			...contactForm,
		})
		if (ok) {
			setError(undefined)
			setDone(true)
		} else {
			setError('Could not send form. Please use email address below.')
		}
	}

	const sendAndRegister = async () => {
		setLoading(true)
		await sendOrder()
		setLoading(false)
	}

	const validEmail = /.+\@.+\..+/i.test(contactForm.email)

	const supportEmail = settings
		.flatMap(s => s.data)
		.find(s => s.attributes.key === SettingKey.SUPPORT_EMAIL)?.attributes.value

	const metaTitle = getSettingValue(SettingKey.META_TITLE)

	return (
		<Layout
			breadcrumbs={[{ link: '/contact/', featureName: 'contact' }]}
			center
		>
			<Helmet>
				<title>{buildMetaTitle([displayName || 'Contact', metaTitle])}</title>
			</Helmet>
			<Container>
				<Heading>Get in Touch</Heading>
				<TextInput
					label="Name*"
					disabled={done}
					value={contactForm.name}
					onChange={name => setContactForm({ ...contactForm, name })}
				/>
				<TextInput
					label="Company"
					disabled={done}
					value={contactForm.company}
					onChange={company => setContactForm({ ...contactForm, company })}
				/>
				<TextInput
					label="Phone"
					disabled={done}
					value={contactForm.phone}
					onChange={phone => setContactForm({ ...contactForm, phone })}
				/>
				<TextInput
					label="E-Mail*"
					disabled={done}
					value={contactForm.email}
					onChange={email => setContactForm({ ...contactForm, email })}
				/>
				<TextInput
					label="Message*"
					type="textarea"
					disabled={done}
					value={contactForm.text}
					onChange={text => setContactForm({ ...contactForm, text })}
				/>
				{done ? (
					<SuccessMessage>
						<MailSuccessIcon />
						<div>
							<SuccessTitle>Thank you for getting in touch!</SuccessTitle>
							<P>
								We appreciate you contacting us. One of our colleagues will get
								back to you shortly.
							</P>
							<P>Have a great day!</P>
						</div>
					</SuccessMessage>
				) : (
					<FlatButton
						disabled={!contactForm.text || !contactForm.name || !validEmail}
						onClick={sendAndRegister}
						loading={loading}
					>
						Send
					</FlatButton>
				)}
				{error && <ErrorText marginTop>{error}</ErrorText>}
				<EmailUs>
					Or feel free to mail us directly via{' '}
					<Link
						href={`mailto:${supportEmail}`}
						target="_blank"
						rel="noopener noreferrer"
					>
						{supportEmail}
					</Link>
					.
				</EmailUs>
			</Container>
		</Layout>
	)
}

const Container = styled.div`
	display: flex;
	flex-direction: column;
	align-items: stretch;
	width: 100%;
	max-width: 600px;
`

const EmailUs = styled.div`
	margin-top: 3em;
	font-size: 1.2em;
`

const SuccessMessage = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
`

const P = styled.p`
	margin: 0.5em 0;
`

const SuccessTitle = styled.p`
	margin: 0.5em 0;
	font-weight: 500;
`
