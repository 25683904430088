import { API_URI } from '.'
import Message from '../models/Message'
import { getHeaders } from './auth'

export async function postMessage(message: Message): Promise<boolean> {
	const response = await fetch(`${API_URI}/messages`, {
		method: 'POST',
		body: JSON.stringify(message),
		headers: getHeaders(),
	})

	return response.ok
}
