import styled, { css } from 'styled-components/macro'

interface Props {
	marginTop?: boolean
}

export default styled.div`
	font-size: 0.8em;
	color: red;

	${({ marginTop }: Props) =>
		marginTop
			? css`
					margin-top: 1em;
			  `
			: undefined}
`
